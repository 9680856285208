import type { Images } from './images';
import images from './images';

export interface Assets {
  images: Images;
}

const assets: Assets = {
  images,
};

export { images };

export default assets;
