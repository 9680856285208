/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/3a950975ef63f00fb9fae58930336959.png */
  'huiyuan_bg_svip.png': '3a950975ef63f00fb9fae58930336959.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/a1feed5ae9192614962c7ed46c2116f1.png */
  'huiyuan_bg_vip_nouse.png': 'a1feed5ae9192614962c7ed46c2116f1.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/36aaf1ef38df460b4b724928f17fdd8b.png */
  'huiyuan_bg_vip.png': '36aaf1ef38df460b4b724928f17fdd8b.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/8688cf289f05468b0bbc76f113e2b6cf.png */
  'icon-add-pic.png': '8688cf289f05468b0bbc76f113e2b6cf.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/df5d36d57eb417bc697db02f46de35d4.png */
  'icon-add-video.png': 'df5d36d57eb417bc697db02f46de35d4.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/f6bd7b6c8554924ebfabdeb310c4e44e.png */
  'icon-channel-shanjian.png': 'f6bd7b6c8554924ebfabdeb310c4e44e.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/5b56a40567fa53e1e30c6de7b4ea18b5.png */
  'icon-channel-wechat.png': '5b56a40567fa53e1e30c6de7b4ea18b5.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ead65a0afc397358cd0af3cc14220dbf.jpg */
  'img-qrcode-gh1280.jpg': 'ead65a0afc397358cd0af3cc14220dbf.jpg',
};
